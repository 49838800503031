import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="not-found">
      <div className="not-found__inner">
        <h2 className="not-found__heading text--3">
          Oops, This Page <span className="underline">Could Not Be Found!</span>
        </h2>
        <Link
          to="/"
          className="not-found__link
                        text--3"
        >
          back to home
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
